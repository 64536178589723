module.exports = [{
      plugin: require('C:/Users/jpoll/josh-dev/testing-talk/gatsby-gitbook-starter/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\Users\\jpoll\\josh-dev\\testing-talk\\gatsby-gitbook-starter\\src\\templates\\docs.js"},
    },{
      plugin: require('C:/Users/jpoll/josh-dev/testing-talk/gatsby-gitbook-starter/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"sizeByPixelDensity":true}},{"resolve":"gatsby-remark-copy-linked-files"}],"extensions":[".mdx",".md"]},
    },{
      plugin: require('C:/Users/jpoll/josh-dev/testing-talk/gatsby-gitbook-starter/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":null,"head":true,"anonymize":false},
    }]
